<template>
	<div class="content--none" v-show="!categories">Click add to create a sub category.</div>
		<div style="text-align:center" v-if="categories.length === 0 && Array.isArray(categories) && !isRootCategory">No Categories</div>
		<div class="category-display"> 
			<all-products-category v-if="isRootCategory"/>
			<product-category v-for="(category,index) in categories" :subId="category.id" :logo="category.image" :key="index" :name="category.name" :image="category.image" :isEnd="category.is_end" :description="category.description"/>
		</div>
        <router-view />
</template>

<script>
import AllProductsCategory from '@/components/products/CategoryAllProducts.vue'
import ProductCategory from '@/components/products/Category.vue'
export default {
    props:{
        categories:Array,
    },
    components:{
        AllProductsCategory,
        ProductCategory, 
    },
    computed:{
		isRootCategory(){
			return this.$store.state.products.isRoot
		}
	},

	mounted(){
		console.log(this.categories)
	}
}
</script>e