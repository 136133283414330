<template>
    <div class="details-button" @click.stop="detailsClick">
        <img class="details-button__image" src="@/assets/images/options.svg" alt="options">
    </div>
</template>
<script>
export default {
    props:{
        routeToDetails:{
            type:String,
        },
        subId:{
            type:String,
            required: true,
        }
    },
    computed:{
        id(){
            return this.$route.params.id
        }
    },
    methods:{
        detailsClick(){
           
            this.$router.push({name:this.routeToDetails, params:{ 
                ...(this.id && {id: this.id,}), 
                subId:this.subId
            }})
        }
    }
}
</script>