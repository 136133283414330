<template>
	<div class="content">
		<list-header :loading="loading" 
		:nameModifier="categoryModifier" :dataLabel="productName" :branch='branch' :buttonFilter="buttonFilter"/>
		<div class="content--loading" v-if="loading">
			<filling-circle color="#057DCD" :size="42" />
		</div>
		<div class="content__list" v-else-if="error"> 
			<span>no content to display</span>	
		</div>
		<div class="content__list" v-else> 
			<product-items v-if="isEnd" :categories="categories"/>
			<category-items v-else :categories="categories"/>		
		</div>
	</div>
</template>

<script>
import ListHeader from '@/components/ListHeader.vue'
import ProductItems from '@/components/products/ProductItems.vue'
import CategoryItems from '@/components/products/CategoryItems.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
	data(){
		return {
			rawProductName:'',
			loading:false,
			modalName:'branch',
			branch:'test branch',

		}
	},
	components: {FillingCircle,CategoryItems,ProductItems,ListHeader},
	name: 'ProductList',
	computed:{
		buttonFilter(){
			return this.isEnd ? 'Category' : 'Product'
		},
		productName(){
			let label;
			if(this.rawProductName==='all_products') label = 'Main Categories' 
			else label = this.rawProductName
			return label
		},
		isEnd(){
			return this.$store.state.products.isEnd
		},
		categoryModifier(){
			return this.isEnd ? "Category" : null
		},

		headerSearchQuery(){
			return this.$store.state.headerSearchQuery.toLowerCase()
		},

		categories(){
			let categories = this.$store.state.products.categories

			let filteredCategories = categories.filter( category => category.name.toLowerCase().includes(this.headerSearchQuery) )

			return filteredCategories
		},
		
		getBranchId(){
			return this.$store.state.branchId
		},
		error(){
			return this.$store.state.error
		},
	},
	methods:{
		loadAllProducts(id){	
			this.loading = true
			this.$store.dispatch({type:'products/loadAllProducts',id })
			.then(()=>{
				this.rawProductName = "All Products"
				this.loading = false
			})
			.catch(()=>{
				this.loading = false
			})
		},
		loadCategories(categoryId){	
			this.loading = true
			this.$store.dispatch({type:'products/loadCategories',categoryId })
			.then((response)=>{
				if(!this.getBranchId) this.setBranchId(response.branch)
				this.rawProductName = response.name
				this.loading = false
			})
			.catch(()=>{
				this.loading = false	
			})
		},
		setBranchId(branchId){
			this.$store.commit({type:'setBranchId',branchId})
		}
		
	},
	mounted(){
		let categoryId = this.$route.params.id

		if(categoryId.includes("_prod_")){
			this.loadAllProducts(categoryId.replace('_prod_',''));
		}
		else this.loadCategories(categoryId)
	}
}
</script>

