<template>
    <div class="product-category product-category--all" @click="selectCategory">
        <!-- <details-button /> -->
        <div class="product-category__image">
        </div>
        <h1 class="product-category__heading">All Products</h1>
    </div>
</template>

<script>
// import DetailsButton from '@/components/DetailsButton.vue'
export default {
  name: 'ProductCategory',
  props:{
      id:String,
  },
  computed:{
      branchID(){
          return this.$route.params.id + "_prod_"
      }
  },
  methods:{
      selectCategory(){  
          this.$router.push({name:'Product-Category',params:{ id:this.branchID }})
      }
  },
}
</script>
