<template>
    <div class="product-category" @click="selectCategory">
        <details-button :subId="subId" routeToDetails="CategoryEdit" />
        <div class="product-category__image" :style="{'background-image':logoURL}">
        </div>

        <h1 class="product-category__heading">{{ name }}</h1>
        <div class="product-category__info">
           {{ description }}
        </div>
        <div class="product-category__info">
           
        </div>
        
    </div>
</template>

<script>
import DetailsButton from '@/components/DetailsButton.vue'
export default {
  name: 'ProductCategory',
  components:{DetailsButton},
  props:{
      subId:String,
      logo:String,
      name:String,
      is_end:Boolean,
      description:String,
  },
  methods:{
      
      selectCategory(){
        if(!this.is_end){
            this.$router.push({name:'Product-Category',params:{ id:this.subId }})
        }
      }
  },
  computed:{
      logoURL(){
          return this.logo ? `url(https://api.vendoorr.com${this.logo})` : null
      }
  },

  mounted(){
      console.log(this.logo)
  }
}
</script>
