<template>
	<product-table :header="header" :list="categories" />
    <router-view />
</template>

<script>
import ProductTable from '@/components/Table.vue'
export default {
	data(){
		return {
			header:{name:'',unit_selling_price:{substitute:'Price'},quantity_remaining:{substitute:'quantity'},description:''}
		}
	},
	components: { ProductTable },
	name: 'ProductList',
	props:{
		categories:Array,
	},
	computed:{
		productsId(){
			return this.$route.params.id
		}
	},

}
</script>
